<template>
  <div class="header-mobile__menu-container">
    <div class="header-mobile__menu-left">
      <a-wait-for-element-spinner
        :selector="$options.consts.HEADER_MENU_SELECTOR"
        class="header-mobile__menu-load-spinner"
        @input="onChangeLoadStatus"
      />
      <div
        v-if="!isMenuExpanded && isHeaderMenuLoaded"
        v-bind="menuOpenBtnAttrs"
        class="header-mobile__hamburger-btn"
        :data-ref="$options.consts.REFS.HEADER_BURGER_MENU_BUTTON"
        @click.stop="openMenu"
      >
        <div v-bind="menuOpenBtnAttrs" class="hamburger-inner"></div>
        <div v-bind="menuOpenBtnAttrs" class="hamburger-inner"></div>
        <div v-bind="menuOpenBtnAttrs" class="hamburger-inner"></div>
      </div>

      <div
        v-else-if="isMenuExpanded"
        class="header-mobile__hamburger-btn"
        @click="closeMenu"
      >
        <button v-show="isHeaderMenuLoaded" class="close" />
      </div>

      <div
        class="header-mobile__one-signal one-signal-mobile-container"
        :data-ref="$options.consts.REFS.ONE_SIGNAL_MOBILE"
      >
        <!-- Note: this icon is going to replace OneSignal's default Bell SVG -->
        <a-one-signal-icon
          :width="headerIconSize"
          :height="headerIconSize"
          :data-ref="$options.consts.REFS.ONE_SIGNAL_ICON_MOBILE"
        />
      </div>
      <div
        class="header-mobile__assistant-btn"
        :data-ref="$options.consts.REFS.ASSISTANT_BUTTON_HEADER_WRAPPER"
      >
        <client-only>
          <a-icon
            v-if="isAssistantButtonVisible"
            :icon="ICON.ASSISTANT_TRANSPARENT"
            :width="headerIconSize"
            :height="headerIconSize"
            :color="ICON_COLOR.WHITE"
            :id="$options.consts.ASSISTANT_BUTTON_ID.HEADER"
            is-button
            aria-label="Assistant"
            @click="openPediaAssistant"
          />
        </client-only>
      </div>
    </div>

    <a-icon
      :icon="ICON.FM_MOBILE"
      :width="32"
      :height="48"
      :to="ROUTE.HOMEPAGE"
      aria-label="Go to FM Homepage"
      fill="#fff"
      no-hover
      class="header__logo-mobile"
    />

    <div class="header-mobile__menu-right">
      <client-only>
        <a-header-actions mobile />
      </client-only>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import { PROP_TYPES, propValidator } from '@/utils/validators'
import { hydrationHelpers } from '@/utils/mixins/hydrationHelpers'
import AIcon, { ICON, ICON_COLOR } from 'shared/AIcon'
import AHeaderActions from '../../Shared/AHeaderActions'
import { ROUTE } from 'enums/routes'
import { REFS } from 'enums/external-refs'
import { ASSISTANT_BUS_NAME } from '@/components/_layout/APediaAssistant/lazy'
import AOneSignalIcon from 'shared/AOneSignalIcon'
import { ASSISTANT_BUTTON_ID } from 'shared/AAssistantButton/enums'
import { HEADER_MOBILE_MENU_OPEN_BTN_ATTRIBUTE } from 'enums/data-attributes'
import { HEADER_ICON_SIZE } from 'enums/header'
import AWaitForElementSpinner from 'shared/AWaitForElementSpinner'

const SCROLL_OFFSET_TO_DISPLAY_ASSISTANT = 100 + 280 // consider top banner

export default {
  name: 'AHeaderMobileTopSection',
  mixins: [hydrationHelpers],
  components: { AIcon, AHeaderActions, AOneSignalIcon, AWaitForElementSpinner },
  model: {
    prop: 'isMenuExpanded'
  },
  props: {
    isMenuExpanded: propValidator([PROP_TYPES.BOOLEAN])
  },
  consts: {
    ASSISTANT_BUTTON_ID,
    REFS,
    HEADER_ICON_SIZE,
    HEADER_MENU_SELECTOR: '.header-mobile-expanded__menu-container'
  },
  data() {
    return {
      ROUTE,
      ICON,
      ICON_COLOR,
      isHeaderMenuLoaded: false
    }
  },
  computed: {
    ...mapState(['scrollTop']),
    ...mapGetters({
      isOnboardingInProgress: `onboarding/isOnboardingInProgress`
    }),
    isAssistantButtonVisible() {
      return (
        this.isOnboardingInProgress ||
        this.scrollTop > SCROLL_OFFSET_TO_DISPLAY_ASSISTANT
      )
    },
    headerIconSize() {
      return this.$_hydrationHelpers_mobileOrDesktopValue(
        HEADER_ICON_SIZE.MOBILE,
        HEADER_ICON_SIZE.DESKTOP
      )
    },
    oneSignalContainerRef() {
      return this.$_hydrationHelpers_mobileOrDesktopValue(
        REFS.ONE_SIGNAL_MOBILE,
        REFS.ONE_SIGNAL_DESKTOP
      )
    },
    oneSignalIconRef() {
      return this.$_hydrationHelpers_mobileOrDesktopValue(
        REFS.ONE_SIGNAL_ICON_MOBILE,
        REFS.ONE_SIGNAL_ICON_DESKTOP
      )
    },
    menuOpenBtnAttrs() {
      return { [HEADER_MOBILE_MENU_OPEN_BTN_ATTRIBUTE]: true }
    }
  },
  methods: {
    openMenu() {
      if (!this.isHeaderMenuLoaded) return

      this.$emit('input', true)
    },
    closeMenu() {
      this.$emit('input', false)
    },
    openPediaAssistant() {
      this.$bus.$emit(ASSISTANT_BUS_NAME)
    },
    onChangeLoadStatus(isLoaded) {
      if (isLoaded) {
        this.isHeaderMenuLoaded = isLoaded
        this.$emit('loaded', isLoaded)
      } else {
        this.closeMenu()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header-mobile__menu-container {
  height: $size-header-expanded;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include tablet {
    height: $tablet-header-height;
  }

  @include mobile {
    height: $mobile-header-height;
  }
}

.header-mobile__menu-left,
.header-mobile__menu-right {
  width: 190px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include mobile {
    width: 125px;
  }
}

.header-mobile__menu-left {
  justify-content: flex-start;
}

.header-mobile__hamburger-btn {
  position: relative;
  width: 31px;
  height: 21px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header-mobile__menu-load-spinner {
  margin-top: -5px;
}

.header-mobile__one-signal {
  position: relative;
  margin-left: $header-icon-gap-desktop;

  @include header-icon-size;

  @include mobile {
    margin-left: $header-icon-gap-mobile;
  }
}

.header-mobile__assistant-btn {
  min-width: $header-icon-mobile;
  margin-left: $header-icon-gap-desktop;

  @include header-icon-size;

  @include mobile {
    margin-left: $header-icon-gap-mobile;
  }
}

.hamburger-inner {
  width: 100%;
  height: 3px;
  border-radius: 4px;
  background-color: white;
}

.header-mobile__menu-logo {
  width: 32px;
  height: 48px;
}

.close {
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  opacity: 0.9;

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  &::before,
  &::after {
    position: absolute;
    top: -5px;
    left: 14px;
    content: ' ';
    height: 33px;
    width: 4px;
    background-color: white;
  }

  &:hover {
    opacity: 1;
  }
}
</style>
